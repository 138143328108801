* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 5px;
  background-color: #e9f3e9;
}

body::-webkit-scrollbar-thumb {
  height: 10px;
  width: 5px;
  background-color: #218225;
  border-radius: 100px;
}

.circleDivLeft {
  position: absolute;
  top: 500px;
  left: -100px;
  overflow: hidden;
  border-radius: 350.784px;
  filter: blur(50px);
}

.circleLeft {
  background: linear-gradient(14deg, #fff 25.71%, #218225 89.85%);
  border-radius: 350.784px;
  width: 350.784px;
  height: 350.784px;
  flex-shrink: 0;
  filter: blur(138.47927856445312px);
  position: relative;
}

.circleDivRight {
  position: absolute;
  top: 500px;
  right: -100px;
  overflow: hidden;
  border-radius: 350.784px;
  filter: blur(50px);
}

.circleRight {
  background: linear-gradient(14deg, #fff 25.71%, #218225 89.85%);
  border-radius: 350.784px;
  width: 350.784px;
  height: 350.784px;
  flex-shrink: 0;
  filter: blur(138.47927856445312px);
  position: relative;
}

/* ul.flex li.active a.inline-flex {
  background-color: #218225;
} */

#servicesHero {
  background-image: url('./Assets/servicesHero.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 1443px;
}

#servicesCon {
  background-image: url('./Assets/servicesCon.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 1390px;
  padding: 200px;
}


#helpinput {
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.2);
}

#aboutus {
  background-image: url("./Assets/abtImg.webp");
  background-color: rgba(0, 0, 0, 1);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: contain;
  mix-blend-mode: color-burn;
  width: auto;
  height: auto;
  padding: 10px 0;
}

#bgHelpSub {
  width: auto;
  height: auto;
  border-radius: 1465px;
  background: #bad8bb;
  filter: blur(177.2935333251953px);
}

#bgHelpSubB {
  width: auto;
  height: auto;
  /* border-radius: 1465px; */
  background: var(--Foundation-Green-Light, #E9F3E9);
  /* filter: blur(177.2935333251953px); */
}

#price-product {
  overflow: hidden;
}


/* Cookie */
.cookie-banner-enter {
  opacity: 0.01;
 }
 
 .cookie-banner-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
 }
 
 .cookie-banner-exit {
  opacity: 1;
 }
 
 .cookie-banner-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
 }

/* #bgHelp {
  background: var(--Foundation-Green-Light, #e9f3e94b);
} */

#dropdwn {
  width: 100vw;
  position: absolute;
  right: 0;
  /* left: 0; */
  margin-left: 20px !important;
}

@media (max-width: 765px) {
  .circleDivLeft {
    top: 400px;
    left: -300px;
  }

  .circleDivRight {
    top: 400px;
    right: -300px;
  }

  #helpbg {
    top:  2300px;
    max-height: 1200px;
  }
  
  #servicesCon {
    padding: 10px;
    background-size: center;
  }
}
