:root {
    --primaryColor: #218225;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    /* Center slide img vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    /* width: 100%; */
    /* height: 40%; */
}

.customActive {
    background-color: var(--primaryColor);
}

.swiper-button-next,
.swiper-button-prev {
    color: var(--primaryColor);
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    opacity: .7;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    width: auto;
    height: auto;
    font-size: 35px;
}

@media (max-width: 765px) {

    .swiper-button-prev,
    .swiper-button-next {
        margin: 120px 0;
        z-index: 100;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
        font-size: 20px;
    }
}